import React from 'react';
import styled from 'styled-components';

const Background = styled.div`
  width: 100%;
  height: ${({ height }) => height || '2600px'};
  margin: 0 0 60px;
  left: 0;
  position: ${({ children }) => (children ? 'relative' : 'absolute')};
  background: linear-gradient(180deg, #f7f5f5 0%, #f7f5f5 86%, #f7f5f5 100%);

  /* background: ${(props) => `linear-gradient(
    180deg,
    ${props.theme.primary.oak} 0%,
    ${props.theme.primary.oak} 86%,
    ${props.theme.secondary.white} 100%
  );`}; */
`;

const HomeHeroBackground = ({ height, children }) => {
  return children ? <Background height={`auto`}>{children}</Background> : <Background height={height} />;
};

export default HomeHeroBackground;
