import React from 'react';
import styled, { withTheme } from 'styled-components';

const DarkBackground = styled.div`
  width: 100%;
  height: 1500px;
  left: 0;
  position: absolute;
  background: ${(props) => props.theme.primary.charcoal};
`;

const DarkBackgroundHero = () => {
  return <DarkBackground />;
};

export default withTheme(DarkBackgroundHero);
