import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  svg {
    width: 100%;
  }
`;
export const Gradient2Background = () => {
  return (
    <Wrapper>
      <svg viewBox="0 0 375 808" fill="none">
        <path fill="#fff" d="M-512 0H887v808H-512z" />
        <path fill="url(#prefix__paint0_radial)" d="M-512 0H887v808H-512z" />
        <path fill="url(#prefix__paint1_radial)" d="M-512 0H887v808H-512z" />
        <path fill="url(#prefix__paint2_radial)" d="M-512 0H887v808H-512z" />
        <path fill="url(#prefix__paint3_radial)" d="M-512 0H887v808H-512z" />
        <path fill="url(#prefix__paint4_radial)" d="M-512 0H887v808H-512z" />
        <path fill="url(#prefix__paint5_radial)" d="M-512 0H887v808H-512z" />
        <path fill="url(#prefix__paint6_radial)" d="M-512 0H887v808H-512z" />
        <defs>
          <radialGradient
            id="prefix__paint0_radial"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(0 808 -1399 0 887 0)"
          >
            <stop stopColor="#DCDBFF" />
            <stop offset={1} stopColor="#DCDBFF" stopOpacity={0} />
          </radialGradient>
          <radialGradient
            id="prefix__paint1_radial"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(0 343.656 -595.018 0 492.684 100.233)"
          >
            <stop stopColor="#DCDBFF" />
            <stop offset={1} stopColor="#DCDBFF" stopOpacity={0} />
          </radialGradient>
          <radialGradient
            id="prefix__paint2_radial"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(0 585.033 -788.413 0 -584.663 689.357)"
          >
            <stop stopColor="#F5665D" />
            <stop offset={0.531} stopColor="#F5665D" stopOpacity={0.2} />
            <stop offset={1} stopColor="#F5665D" stopOpacity={0} />
          </radialGradient>
          <radialGradient
            id="prefix__paint3_radial"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(0 335.473 -486.076 0 187.5 840.729)"
          >
            <stop stopColor="#9FFBF5" />
            <stop offset={1} stopColor="#9FFBF5" stopOpacity={0} />
          </radialGradient>
          <radialGradient
            id="prefix__paint4_radial"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(418.537 0 0 807.622 -181.627 992.101)"
          >
            <stop stopColor="#F5665D" />
            <stop offset={0.635} stopColor="#F5665D" stopOpacity={0.2} />
            <stop offset={1} stopColor="#F5665D" stopOpacity={0} />
          </radialGradient>
          <radialGradient
            id="prefix__paint5_radial"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(0 695.494 -923.172 0 283.415 -251.605)"
          >
            <stop stopColor="#F5665D" />
            <stop offset={0.635} stopColor="#F5665D" stopOpacity={0.2} />
            <stop offset={1} stopColor="#F5665D" stopOpacity={0} />
          </radialGradient>
          <radialGradient
            id="prefix__paint6_radial"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(461.166 0 0 486.846 -561.411 353.884)"
          >
            <stop stopColor="#FCFF7D" />
            <stop offset={0.635} stopColor="#FCFF7D" stopOpacity={0.2} />
            <stop offset={1} stopColor="#FCFF7D" stopOpacity={0} />
          </radialGradient>
        </defs>
      </svg>
    </Wrapper>
  );
};
