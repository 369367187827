import React from 'react';

function YellowRadial() {
  return (
    <svg width={232} height={232} viewBox="0 0 232 232" fill="none">
      <circle
        opacity={0.4}
        cx={116}
        cy={116}
        r={116}
        fill="url(#prefix__yellow_radial)"
      />
      <defs>
        <radialGradient
          id="prefix__yellow_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 104.301 -116 0 116 127.699)"
        >
          <stop offset={0.302} stopColor="#FCFF7D" />
          <stop offset={1} stopColor="#FCFF7D" stopOpacity={0} />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default YellowRadial;
