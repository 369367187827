import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
export const Gradient1Background = () => {
  return (
    <Wrapper>
      <svg viewBox="0 0 1440 696">
        <path fill="#fff" d="M-2 0h1444v696H-2z" />
        <path fill="url(#prefix__paint0_radial)" d="M-2 0h1444v696H-2z" />
        <path fill="url(#prefix__paint1_radial)" d="M-2 0h1444v696H-2z" />
        <path fill="url(#prefix__paint2_radial)" d="M-2 0h1444v696H-2z" />
        <path fill="url(#prefix__paint3_radial)" d="M-2 0h1444v696H-2z" />
        <path fill="url(#prefix__paint4_radial)" d="M-2 0h1444v696H-2z" />
        <path fill="url(#prefix__paint5_radial)" d="M-2 0h1444v696H-2z" />
        <path fill="url(#prefix__paint6_radial)" d="M-2 0h1444v696H-2z" />
        <defs>
          <radialGradient
            id="prefix__paint0_radial"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(0 696 -1444 0 1442 0)"
          >
            <stop stopColor="#DCDBFF" />
            <stop offset={1} stopColor="#DCDBFF" stopOpacity={0} />
          </radialGradient>
          <radialGradient
            id="prefix__paint1_radial"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(0 296.02 -614.157 0 1035 86.34)"
          >
            <stop stopColor="#DCDBFF" />
            <stop offset={1} stopColor="#DCDBFF" stopOpacity={0} />
          </radialGradient>
          <radialGradient
            id="prefix__paint2_radial"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(0 503.939 -813.773 0 -77 593.803)"
          >
            <stop stopColor="#F5665D" />
            <stop offset={0.531} stopColor="#F5665D" stopOpacity={0.2} />
            <stop offset={1} stopColor="#F5665D" stopOpacity={0} />
          </radialGradient>
          <radialGradient
            id="prefix__paint3_radial"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(0 288.972 -501.711 0 720 724.192)"
          >
            <stop stopColor="#9FFBF5" />
            <stop offset={1} stopColor="#9FFBF5" stopOpacity={0} />
          </radialGradient>
          <radialGradient
            id="prefix__paint4_radial"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(432 0 0 695.674 339 854.582)"
          >
            <stop stopColor="#F5665D" />
            <stop offset={0.635} stopColor="#F5665D" stopOpacity={0.2} />
            <stop offset={1} stopColor="#F5665D" stopOpacity={0} />
          </radialGradient>
          <radialGradient
            id="prefix__paint5_radial"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(0 599.089 -952.867 0 819 -216.729)"
          >
            <stop stopColor="#F5665D" />
            <stop offset={0.635} stopColor="#F5665D" stopOpacity={0.2} />
            <stop offset={1} stopColor="#F5665D" stopOpacity={0} />
          </radialGradient>
          <radialGradient
            id="prefix__paint6_radial"
            cx={0}
            cy={0}
            r={1}
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(476 0 0 419.362 -53 304.83)"
          >
            <stop stopColor="#FCFF7D" />
            <stop offset={0.635} stopColor="#FCFF7D" stopOpacity={0.2} />
            <stop offset={1} stopColor="#FCFF7D" stopOpacity={0} />
          </radialGradient>
        </defs>
      </svg>
    </Wrapper>
  );
};
