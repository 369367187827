import React from 'react';

function GreenRadial() {
  return (
    <svg width={309} height={309} viewBox="0 0 309 309" fill="none">
      <circle
        opacity={0.2}
        cx={154.5}
        cy={154.5}
        r={154.5}
        fill="url(#prefix__green_radial)"
      />
      <defs>
        <radialGradient
          id="prefix__green_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 138.919 -154.5 0 154.5 170.081)"
        >
          <stop offset={0.302} stopColor="#94FFFF" />
          <stop offset={1} stopColor="#94FFFF" stopOpacity={0} />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default GreenRadial;
