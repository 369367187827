import React from 'react';

function PurpleRadial() {
  return (
    <svg width={302} height={302} viewBox="0 0 302 302" fill="none">
      <circle
        opacity={0.8}
        cx={151}
        cy={151}
        r={151}
        fill="url(#prefix__purple_radial)"
      />
      <defs>
        <radialGradient
          id="prefix__purple_radial"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 135.772 -151 0 151 166.228)"
        >
          <stop offset={0.302} stopColor="#D5C5D7" />
          <stop offset={1} stopColor="#D5C5D7" stopOpacity={0} />
        </radialGradient>
      </defs>
    </svg>
  );
}

export default PurpleRadial;
